import {createApp} from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

if (process.env.NODE_ENV === 'production') {
	// eslint-disable-next-line no-console
	console.warn = () => null
}

createApp(App).use(store).use(router).mount('#app')
